import React from 'react';
import { RouteComponentProps } from 'react-router';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import './BlogPageDetailView.css';
import DOMPurify from 'dompurify';
import formatDate from '../../helpers/formatDate';
import { Spinner } from 'react-bootstrap';
/* eslint-disable @typescript-eslint/no-var-requires */
const HtmlToReactParser = require('html-to-react').Parser;

interface ReactRouterParams {
  blogId: string;
}

interface BlogPageDetailViewState {
  id: string;
  image: string;
  title: string;
  date: string;
  htmlText: string;
  isLoading: boolean;
}

class BlogPageDetailView extends React.Component<RouteComponentProps<ReactRouterParams>, BlogPageDetailViewState> {
  constructor(props: Readonly<RouteComponentProps<ReactRouterParams>>) {
    super(props);

    this.state = {
      id: '0',
      image: '',
      title: '',
      date: '',
      htmlText: '',
      isLoading: true,
    };
  }

  async componentDidMount(): Promise<void> {
    const blogId = this.props.match.params.blogId;
    const blogRes = await fetch(
      `https://percysbricks.ch/blog_wordpress/index.php?rest_route=/wp/v2/posts/${blogId}&_embed`,
    );
    const blogJson = await blogRes.json();
    this.setState({ isLoading: false });

    let image = null;
    if (blogJson._embedded['wp:featuredmedia']) {
      image = blogJson._embedded['wp:featuredmedia'][0].source_url;
    }

    this.setState({
      id: blogId,
      image,
      title: blogJson.title.rendered,
      htmlText: blogJson.content.rendered,
      date: blogJson.date,
    });
  }

  render(): JSX.Element {
    const purifiedHtml = DOMPurify.sanitize(this.state.htmlText, { USE_PROFILES: { html: true } });
    const htmlToReactParser = new HtmlToReactParser();
    const element = htmlToReactParser.parse(purifiedHtml);

    return (
      <div className="wrapper">
        <NavBar />
        {this.state.isLoading ? (
          <div id="spinnerContainer">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div id="blogpageDetail">
            <div id="blogCard">
              <div id="imageContainer">
                <img id="titleImage" src={this.state.image}></img>
              </div>
              <p>{formatDate(new Date(this.state.date))}</p>
              <h4 className="font-weight-bold">{this.state.title}</h4>
              {element}
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default BlogPageDetailView;
