export class EmailService {
  static async postMail(
    firstName: string,
    lastName: string,
    email: string,
    subject: string,
    message: string,
  ): Promise<Response> {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_IP}/api/mail`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        subject,
        message,
      }),
    });
    return res;
  }
}
