export default function formatDate(date: Date): String {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  if (Number.parseInt(day) < 10) {
    day = '0' + day;
  }

  if (Number.parseInt(month) < 10) {
    month = '0' + month;
  }

  const hours = date.getHours();
  let minutes = date.getMinutes().toString();

  if (Number.parseInt(minutes) < 10) {
    minutes = '0' + minutes;
  }

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}
