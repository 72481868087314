import bricklink from '../../assets/shopicons/single_legos.png';
import legoMinifigur from '../../assets/shopicons/lego_minifigur.png';
import sets from '../../assets/shopicons/sets.png';
import services from '../../assets/shopicons/services.png';
import './Shops.css';
import React from 'react';

interface Shop {
  link: string;
  logo: string;
  description: string;
}

interface ShopsState {
  shops: Shop[];
}

class Shops extends React.Component<{}, ShopsState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      shops: [
        {
          link: 'https://store.bricklink.com/Percys_Bricks&utm_content=globalnav#/terms',
          logo: bricklink,
          description: 'Einzelteile',
        },
        {
          link: 'https://www.ricardo.ch/de/shop/PercysBricks/offers/',
          logo: legoMinifigur,
          description: 'Minifiguren',
        },
        {
          link: 'https://shop.percysbricks.ch',
          logo: sets,
          description: 'EOL-Sets',
        },
        { link: './pdfs/Services.pdf', logo: services, description: 'Services' },
      ],
    };
  }

  render(): JSX.Element {
    return (
      <div id="shop">
        {this.state.shops.map((shop) => (
          /** set class for eol --> picture is wider than the other one */
          <a
            href={shop.link}
            target="_blank"
            rel="noreferrer"
            className={`shopItemWrapper ${shop.description === 'EOL-Sets' ? 'eol' : ''}`}
          >
            <div className="shopItem">
              <img className="card-img-top" src={shop.logo} alt="Card image cap" />
              <div className="card-body">
                <h4 className="card-text font-weight-bold">{shop.description}</h4>
              </div>
            </div>
          </a>
        ))}
      </div>
    );
  }
}

export default Shops;
