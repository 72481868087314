import React from 'react';
import './Footer.css';
import twitterIcon from '../../assets/icon/twitter_icon.png';
import instagramIcon from '../../assets/icon/instagram_icon.png';
import facebookIcon from '../../assets/icon/facebook_icon.png';
import youtubeIcon from '../../assets/icon/youtube_icon.png';
import linkedinIcon from '../../assets/icon/linkedin_icon.png';
import tiktokIcon from '../../assets/icon/tiktok_icon.png';

interface SocialMedia {
  link: string;
  icon: string;
}

interface FooterState {
  socialMediaChannels: SocialMedia[];
}

class Footer extends React.Component<{}, FooterState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      socialMediaChannels: [
        { link: 'https://www.instagram.com/percys.bricks/', icon: instagramIcon },
        { link: 'https://www.facebook.com/Percys-Bricks-101247365402649', icon: facebookIcon },
        { link: 'https://www.youtube.com/channel/UCuxgDF_01ybuAyD_MWahEOg', icon: youtubeIcon },
        { link: 'https://twitter.com/Percys_Bricks', icon: twitterIcon },
        { link: '', icon: linkedinIcon },
        { link: 'https://vm.tiktok.com/ZMeymmEVa', icon: tiktokIcon },
      ],
    };
  }

  render(): React.ReactNode {
    return (
      <div id="footer">
        <p>© Percy’s Bricks 2021. Alle Rechte vorbehalten. </p>
        <p id="secondTextFooter">
          LEGO® ist eine Marke der LEGO Gruppe. Die vorliegende Website wird nicht von der LEGO Gruppe gesponsert,
          genehmigt oder unterstützt
        </p>
        <div id="socialMedia">
          {this.state.socialMediaChannels.map((socialMedia, index) => (
            <div key={index} className="icon">
              <a target="_blank" rel="noreferrer" href={socialMedia.link}>
                <img src={`${socialMedia.icon}`} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Footer;
