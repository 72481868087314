import React from 'react';
import brandImage from '../../assets/brand_logo.png';
import NavDropdown from '../NavDropdown/NavBar';
import './NavBar.css';

class NavBar extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (
      <div id="navbar">
        <div id="sites">
          <a href="/">
            <img alt="" src={brandImage} width="150" height="60" className="d-inline-block align-top" />
          </a>
          <div id="pages">
            <a href="https://shop.percysbricks.ch">
              <p>Shop</p>
            </a>
            <a href="/blogpage">
              <p>Blog</p>
            </a>
            <a href="/aboutus">
              <p>Über uns</p>
            </a>
            <a href="/contact">
              <p>Kontakt</p>
            </a>
          </div>
          <div id="lang">
            <a href="#">DE|</a>
            <a href="#" onClick={() => alert('Translation is in progress. Please be patient.')}>
              EN|
            </a>
            <a href="#" onClick={() => alert('La traduction est en cours. Veuillez faire preuve de patience.')}>
              FR|
            </a>
            <a href="#" onClick={() => alert('La traduzione è in corso. Per favore, abbiate pazienza.')}>
              IT
            </a>
          </div>
          <NavDropdown />
        </div>
        <div id="bar">
          <div className="beam" />
        </div>
      </div>
    );
  }
}

export default NavBar;
