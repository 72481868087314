import React from 'react';
import './NavDropdown.css';
import { Dropdown } from 'react-bootstrap';
import hamburgerIcon from '../../assets/icon/hamburger_icon.png';

class NavDropdown extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (
      <Dropdown id="nav-dropdown">
        <Dropdown.Toggle id="dropdown-basic">
          <span>
            <img alt="" src={hamburgerIcon} width="30" height="30" className="d-inline-block align-top" />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/shop">Shop</Dropdown.Item>
          <Dropdown.Item href="/blogpage">Blog</Dropdown.Item>
          <Dropdown.Item href="/aboutus">Über uns</Dropdown.Item>
          <Dropdown.Item href="/contact">Kontakt</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#">Deutsch</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => alert('Translation is in progress. Please be patient.')}>
            Englisch
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => alert('La traduction est en cours. Veuillez faire preuve de patience.')}
          >
            Französisch
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => alert('La traduzione è in corso. Per favore, abbiate pazienza.')}>
            Italienisch
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default NavDropdown;
