import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import './BlogPage.css';
import clip from 'text-clipper';
import DOMPurify from 'dompurify';
import formatDate from '../../helpers/formatDate';
import brandLogo from '../../assets/brand_logo.png';
/* eslint-disable @typescript-eslint/no-var-requires */
const HtmlToReactParser = require('html-to-react').Parser;

interface Blog {
  id: string;
  image: string;
  title: string;
  date: string;
  htmlText: string;
  previewHtml: string;
}

interface BlogPageState {
  blogs: Blog[];
  isLoading: boolean;
}

class BlogPage extends React.Component<{}, BlogPageState> {
  private readonly HTML_MAX_LENGTH: number = 100;

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      blogs: [],
      isLoading: true,
    };
  }

  async componentDidMount(): Promise<void> {
    const blogsRes = await fetch('https://percysbricks.ch/blog_wordpress/index.php?rest_route=/wp/v2/posts&_embed');
    this.setState({ isLoading: false });
    const blogsJson = await blogsRes.json();

    const blogs: Blog[] = [];

    // transfer it to blog array
    for (const blog of blogsJson) {
      let image = null;
      if (blog._embedded['wp:featuredmedia']) {
        image = blog._embedded['wp:featuredmedia'][0].source_url;
      } else {
        image = brandLogo;
      }

      const htmlText = blog.content.rendered;

      const previewHtml = clip(htmlText, this.HTML_MAX_LENGTH, { html: true, maxLines: 5 });

      const newBlog: Blog = {
        id: blog.id,
        htmlText,
        title: blog.title.rendered,
        date: blog.date,
        image,
        previewHtml: previewHtml,
      };

      blogs.push(newBlog);
    }

    blogs.sort((a, b) => {
      const date1 = new Date(a.date);
      const date2 = new Date(b.date);
      return date2.getTime() - date1.getTime();
    });

    this.setState({ blogs: blogs });
  }

  render(): JSX.Element {
    return (
      <div className="wrapper">
        <NavBar />
        {this.state.isLoading ? (
          <div id="spinnerContainer">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div id="blogs">
            {this.state.blogs.map((blog) => {
              const date = new Date(blog.date);
              const formattedDate = formatDate(date);
              const purifiedHtml = DOMPurify.sanitize(blog.previewHtml, { USE_PROFILES: { html: true } });
              const htmlToReactParser = new HtmlToReactParser();
              const element = htmlToReactParser.parse(purifiedHtml);

              return (
                <div key={blog.id} className="cardWrapper">
                  <a href={`/blogpage/${blog.id}`}>
                    <Card style={{ width: '18rem', height: '36rem' }}>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <Card.Title className="font-weight-bold">{blog.title}</Card.Title>
                        <Card.Text>{element}</Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <small className="text-muted">{formattedDate}</small>
                      </Card.Footer>
                    </Card>
                  </a>
                </div>
              );
            })}
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default BlogPage;
