import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import ContactPage from './pages/ContactPage/ContactPage';
import BlogPage from './pages/BlogPage/BlogPage';
import AboutsUsPage from './pages/AboutUsPage';
import BlogPageDetailView from './pages/BlogPageDetailView/BlogPageDetailView';

function App(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route path="/contact" component={ContactPage} />
        <Route path="/aboutus" component={AboutsUsPage} />
        <Route path="/blogpage/:blogId" component={BlogPageDetailView} />
        <Route path="/blogpage" component={BlogPage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  );
}

export default App;
