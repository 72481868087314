import React from 'react';
import './ContactPage.css';
import { EmailService } from '../../services/EmailService';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import ContactForm from '../../components/ContactForm/ContactForm';

interface ContactPageState {
  result?: string;
  error?: string;
}

class ContactPage extends React.Component<{}, ContactPageState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      result: '',
      error: '',
    };
  }

  private async sendForm(
    firstName: string,
    lastName: string,
    email: string,
    message: string,
    subject: string,
  ): Promise<void> {
    this.setState({
      result: '',
      error: '',
    });

    const res = await EmailService.postMail(firstName, lastName, email, subject, message);

    if (res.status === 200) {
      this.setState({ result: 'E-Mail erfolgreich versendet. Ich melde mich bald bei dir :)' });
    } else {
      const resJson = await res.json();
      this.setState({ error: resJson.error });
    }
  }

  render(): JSX.Element {
    return (
      <div className="wrapper">
        <NavBar />
        <div id="contact">
          <div id="impressum">
            <div id="impressumContainer">
              <div id="adress">
                <p>Percy Spielwarenhandel</p>
                <p>Burgstrasse 87</p>
                <p>9000 St.Gallen</p>
              </div>

              <div id="infosImpressum">
                <p>
                  <a href="mailto:info@percysbricks.ch">info@percysbricks.ch</a>
                </p>
                <p>+41 (0)78 686 78 75</p>
                <p>
                  <a href="www.percysbricks.ch">www.percysbricks.ch</a>
                </p>
              </div>

              <div id="uid">
                <p>UID: CHE-393.170.097</p>
                <p>Eigentümer: Thomas Percy</p>
              </div>

              <div id="greylineImpressum" />

              <div id="agb">
                <a href="./pdfs/Allgemeine_Geschäftsbedinungen.pdf" target="_blank">
                  AGB
                </a>
                <a href="./pdfs/Versandbedinungen.pdf" target="_blank">
                  Versandbedingungen
                </a>
                <a href="./pdfs/Zahlungsoptionen.pdf" target="_blank">
                  Zahlungsoptionen
                </a>
                <a href="./pdfs/Rückgaberichtlinie.pdf" target="_blank">
                  Rückgaberichtlinie
                </a>
                <a href="./pdfs/Haftungsauschluss.pdf" target="_blank">
                  Haftungsauschluss
                </a>
                <a href="./pdfs/Datenschutzerklärung.pdf" target="_blank">
                  Datenschutzerklärung
                </a>
                <a href="./pdfs/Impressum.pdf" target="_blank">
                  Impressum
                </a>
              </div>
            </div>
          </div>
          <div id="greylineSplitter" />
          <div id="form">
            <ContactForm sendForm={this.sendForm.bind(this)} />
            {this.state.result ? <p id="resultText">{this.state.result}</p> : null}
            {this.state.error ? <p id="errorText">{this.state.error}</p> : null}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactPage;
