import React from 'react';
import { Button } from 'react-bootstrap';
import './ContactForm.css';

interface ContactFormState {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

interface ContactFormProps {
  sendForm: (firstName: string, lastName: string, email: string, subject: string, message: string) => void;
}

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  constructor(props: Readonly<ContactFormProps>) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
    };
  }

  render(): React.ReactNode {
    return (
      <div id="formContainer">
        <h4>
          Du hast eine Frage, Hinweis oder Kritik an uns? Schreibe uns eine Nachricht – wir sind gerne für dich da.
        </h4>
        <div id="name">
          <input
            className="form-control"
            placeholder="Vorname"
            type="text"
            value={this.state.firstName}
            onChange={(evt) => this.setState({ firstName: evt.target.value })}
          />
          <input
            className="form-control"
            placeholder="Nachname"
            type="text"
            value={this.state.lastName}
            onChange={(evt) => this.setState({ lastName: evt.target.value })}
          />
        </div>
        <input
          className="form-control"
          placeholder="Email"
          value={this.state.email}
          onChange={(evt) => this.setState({ email: evt.target.value })}
        />
        <input
          className="form-control"
          placeholder="Betreff"
          value={this.state.subject}
          onChange={(evt) => this.setState({ subject: evt.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Schreibe mir hier deine Nachricht."
          rows={6}
          cols={3}
          value={this.state.message}
          onChange={(evt) => this.setState({ message: evt.target.value })}
        />
        <Button
          onClick={() =>
            this.props.sendForm(
              this.state.firstName,
              this.state.lastName,
              this.state.email,
              this.state.message,
              this.state.subject,
            )
          }
        >
          Senden
        </Button>
      </div>
    );
  }
}

export default ContactForm;
