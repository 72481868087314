import React from 'react';
import portrait from '../assets/portraitFoto.jpg';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/NavBar/NavBar';
import './AboutUsPage.css';

class AboutsUsPage extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (
      <div className="wrapper">
        <NavBar />
        <div id="aboutus">
          <div id="aboutUsImage">
            <img src={portrait} />
            <div id="aboutsUsImageDescr">
              <p>Thomas Percy</p>
              <p>Geschäftsführer</p>
            </div>
          </div>
          <div id="greyline" />
          <div id="description">
            <p>
              Percy&#39;s Bricks ist ein aufstrebender Online-Store, welcher dir Stein für Stein eine breitere Auswahl
              an Einzelteilen, Minifiguren und EOL-Sets von LEGO® anbieten möchte.
            </p>
            <p>Unser Angebot findest du in unseren diversen Shops, auf denen wir präsent sind.</p>
            <p>
              Gerne bieten wir dir nach Absprache auch individuelle Services wie zum Beispiel das Aufstöbern von
              seltenen Minifiguren an.
            </p>
            <p>
              Wenn du uns auf unserem Weg begleiten und hinter die Kulissen blicken möchtest, dann folge uns doch auf
              Social Media.
            </p>
            <p>
              Ganz nach unserem Motto &#34;Du suchst. Wir haben.&#34; würden wir uns freuen, wenn wir von dir hören
              dürfen.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AboutsUsPage;
