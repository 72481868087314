import React from 'react';
import './HomePage.css';

import Shops from '../../components/Shops/Shops';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

class HomePage extends React.Component<{}, {}> {
  constructor(props: Readonly<{}>) {
    super(props);
  }

  // hintergrund helles grau ausprobieren

  render(): JSX.Element {
    return (
      <div className="wrapper">
        <NavBar />
        <div id="home">
          <Shops />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
